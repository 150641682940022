import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import moment from 'moment'
import he from 'he'

import SEO from '../components/seo'

class PostTemplate extends Component {

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  render() {
    let { post } = this.props.data

    return (
      <>
        <SEO title={he.decode(post.yoast_meta.yoast_wpseo_title)} bodyClass="post" description={post.yoast_meta.yoast_wpseo_metadesc} />
        <section className='post__heading' style={{backgroundColor: post.acf.color}}>
          <div className='post__heading__inner'>
            <div className='post__heading__content'>
              <h1>{ post.title }</h1>
              <p>{ moment(post.date).format('DD.MM.YY') } - { post.acf.author }</p>
            </div>
            <Fade bottom distance='40px'>
              <picture>
                { this.renderImage(post.acf.feature_image) }
              </picture>
            </Fade>
          </div>
        </section>

        <section className='post__content' dangerouslySetInnerHTML={{__html: post.content}} />
      </>
    )
  }
}

export default PostTemplate

export const query = graphql`
  query ($id: String!) {
    post: wordpressPost(id: {eq: $id}) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      id
      slug
      title
      content
      date
      acf {
        color
        author
        feature_image {
          ...original
        }
      }
    }
  }
`
